import _ from 'lodash'
import { buildQueryStringFromJSON } from 'lib/utils'
import primaryGraphql from '../graphql'

const REST_API_URI = process.env.REACT_APP_REST_API_URI
const LOOKUP_API_URI = process.env.REACT_APP_LOOKUP_API_URI
const LOOKUP_API_BETA_URI = process.env.REACT_APP_LOOKUP_API_BETA_URI
const STATS_API_URI = process.env.REACT_APP_STATS_API_URI
const INVENTORY_API_URI = process.env.REACT_APP_INVENTORY_API_URI
const LOGS_API_URI = process.env.REACT_APP_LOGS_API_URI
const WAREHOUSE_API_URI = process.env.REACT_APP_WAREHOUSE_API_URI
const CHECKINS_API_URI = process.env.REACT_APP_CHECKINS_API_URI
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_RETURN = `${window.location.origin}`
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_KEY
const ALERTS_API_URI = process.env.REACT_APP_ALERTS_API_URI
const REPORTING_API_URI = process.env.REACT_APP_REPORTING_API_URI

const getAccessTokenHeaders = () => {
  const token = window.localStorage.getItem('token')
  const authUserId = window.localStorage.getItem('authUserId')
  if (process.env.REACT_APP_NODE_ENV === 'local' && process.env.REACT_APP_LOCAL_USER) {
    return {
      'Authorization': `Bearer BLARGH`,
      'X-User-ID': process.env.REACT_APP_LOCAL_USER,
      'X-SimplyBail-App-Id': 'RETAIL'
    }
  }
  if (token && authUserId) {
    try {
      return {
        'Authorization': `Bearer ${token}`,
        'X-User-ID': authUserId,
        'X-SimplyBail-App-Id': 'RETAIL',
        'X-Service-Id': process.env.REACT_APP_WAREHOUSE_SERVICE_ID
      }
    } catch(err) {
      console.error(err)
    }
  }

  return {}
}

const http = async (endpoint, options, isAuth) => {
  if (isAuth) {
    options.headers =_.extend(options.headers, getAccessTokenHeaders())
    if (!options.headers['Authorization']) {
      localStorage.removeItem('token')
      localStorage.removeItem('authUserId')
      window.location = `https://${AUTH0_DOMAIN}/v2/logout?returnTo=${AUTH0_RETURN}&client_id=${AUTH0_CLIENT_ID}`
    }
  }

  try {
    const response = await fetch(endpoint, options)
    if (response) {
      const data = await response.json()
      if (response.ok && data) {
        return data
      } else {
        if (isAuth && data && data.error && data.error.name && data.error.name === "UnauthorizedError") {
          window.location = `/callback`
        }
        throw new Error(JSON.stringify({ status: response.status, error: data, logout: false }))
      }
    } else {
      throw new Error('External server error')
    }
  } catch(error) {
    // Error
    if (isAuth) {
      if (error && error.response) {
        if (error.response.data && error.response.data.message) {
          const { message } = error.response.data
          if (message.toLowerCase().indexOf('token invalid') >= 0 || message.toLowerCase().indexOf('jwt expired') >= 0) {
            // window.localStorage.removeItem('userInfo')
            window.location = '/callback'
          }
        }
      } else if (error && error.message && error.message.toLowerCase().indexOf('jwt expired') >= 0) {
        window.location = '/callback'
      }
    }
    throw error
  }
}

const get = (endpoint, options = {}, isAuth = false) => {
  return http(REST_API_URI + endpoint, options, isAuth)
}

const getStats = (endpoint, options = {}, isAuth = false) => {
  return http(STATS_API_URI + endpoint, options, isAuth)
}

const getLookup = (endpoint, options = {}, isAuth = false) => {
  return http(LOOKUP_API_URI + endpoint, options, isAuth)
}

const getLogs = (endpoint, options = {}, isAuth = false) => {
  return http(LOGS_API_URI + endpoint, options, isAuth)
}

const getCheckins = (endpoint, options = {}, isAuth = false) => {
  return http(CHECKINS_API_URI + endpoint, options, isAuth)
}

const getAlerts = (endpoint, options = {}, isAuth = false) => {
  return http(ALERTS_API_URI + endpoint, options, isAuth)
}

const getReporting = (endpoint, options = {}, isAuth = false) => {
  return http(REPORTING_API_URI + endpoint, options, isAuth)
}

const postLookup = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  let lookupUri = LOOKUP_API_URI
  if (payload.is_beta) {
    lookupUri = LOOKUP_API_BETA_URI
  }

  return http(lookupUri + endpoint, options, isAuth)
}

const postCheckins = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  return http(CHECKINS_API_URI + endpoint, options, isAuth)
}

const postAlert = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  return http(ALERTS_API_URI + endpoint, options, isAuth)
}

const postReporting = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  return http(REPORTING_API_URI + endpoint, options, isAuth)
}

const post = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  return http(REST_API_URI + endpoint, options, isAuth)
}

const put = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  }

  return http(REST_API_URI + endpoint, options, isAuth)
}

const removeAlert = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(payload)
  }

  return http(ALERTS_API_URI + endpoint, options, isAuth)
}

const removeReporting = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(payload)
  }

  return http(REPORTING_API_URI + endpoint, options, isAuth)
}

const remove = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(payload)
  }

  return http(REST_API_URI + endpoint, options, isAuth)
}

// Inventory

const getInventory = (endpoint, options = {}, isAuth = false) => {
  return http(INVENTORY_API_URI + endpoint, options, isAuth)
}

const postInventory = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  }

  return http(INVENTORY_API_URI + endpoint, options, isAuth)
}

const putAlert = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  }

  return http(ALERTS_API_URI + endpoint, options, isAuth)
}

const putReporting = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  }

  return http(REPORTING_API_URI + endpoint, options, isAuth)
}

const putInventory = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  }

  return http(INVENTORY_API_URI + endpoint, options, isAuth)
}

const removeInventory = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(payload)
  }

  return http(INVENTORY_API_URI + endpoint, options, isAuth)
}

// Warehouse

const getWarehouse = (endpoint, options = {}, isAuth = false) => {
  options = { ...options,
    headers: { ...options.headers }
  }
  return http(WAREHOUSE_API_URI + endpoint, options, isAuth)
}

const postWarehouse = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'POST',
    headers: { ...headers },
    body: JSON.stringify(payload)
  }

  return http(WAREHOUSE_API_URI + endpoint, options, isAuth)
}

const putWarehouse = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'PUT',
    headers: { ...headers },
    body: JSON.stringify(payload)
  }

  return http(WAREHOUSE_API_URI + endpoint, options, isAuth)
}

const removeWarehouse = (endpoint, payload, headers = {}, isAuth = false) => {
  const options = {
    method: 'DELETE',
    headers: { ...headers },
    body: JSON.stringify(payload)
  }

  return http(WAREHOUSE_API_URI + endpoint, options, isAuth)
}

const appearances = {
  fetchSnapshot: () => get(`/appearances/snapshot`, {}, true),
  fetchReporting: (query) => get(`/appearances/reporting${query}`, {}, true)
}

const alerts = {
  getAgencyAlerts: (id, options = null) => getAlerts(`/agency/${id}/watches${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  getAgencyAlertStats: (id) => getAlerts(`/agency/${id}/watch/stats`, {}, {}, true),
  getAgencyAlert: (id, caseId) => getAlerts(`/agency/${id}/case/${caseId}/watches`, {}, true),
  createCaseAlert: (agencyId, caseId, params) => postAlert(`/agency/${agencyId}/case/${caseId}/watch`, params, {}, true),
  createAlert: (id, params) => postAlert(`/agency/${id}/watch`, params, {}, true),
  removeAlert: (agencyId, id) => removeAlert(`/agency/${agencyId}/watch/${id}`, {}, {}, true),
  getContacts: id => getAlerts(`/agency/${id}/contacts`, {}, true),
  createContact: (id, params) => postAlert(`/agency/${id}/contact`, params, {}, true),
  updateContacts:  (id, contactId, params) => putAlert(`/agency/${id}/contact/${contactId}`, params, {}, true),
  removeContact: (id, contactId) => removeAlert(`/agency/${id}/contact/${contactId}`, {}, {}, true)
}

const arrestee = {
  fetchReceipt: token => get(`/receipt/${token}`, {}, false),
  generateReceipt: (token, data) => post(`/receipt/${token}/generate`, data, {}, false),
  fetchToken: params => post(`/arrestee/token`, params, {}, false),
  postPayment: params => post(`/arrestee/payment`, params, {}, false),
  fetchArrestee: (token) => get(`/arrestee/${token}`, {}, false),
  fetchArresteeById: (id) => get(`/arrestee/case/${id}`, {}, false),
  searchAccounts: params => post(`/arrestee/search`, params, {}, false),
  recoverToken: id => post(`/arrestee/${id}/recover`, {}, {}, false),
  searchBondsmen: token => get(`/arrestee/${token}/bondsmen`, {}, false),
  updateSecondIndemnitor: (token, params) => put(`/arrestee/${token}/second_indemnitor`, params, {}, false),
  searchBondsmenAgents: (bondsmanId, countyId) => get(`/arrestee/bondsman/${bondsmanId}/agents?county=${countyId}`, {}, false),
  selectBondsman: (token, params) => put(`/arrestee/${token}/bondsman`, params, {}, false),
  fetchPayments: token => get(`/arrestee/${token}/payments`, {}, false),
  createPaymentCustomer: (token, params) => post(`/arrestee/${token}/customer`, params, {}, false),
  savePaymentInfo: (token, params) => post(`/arrestee/${token}/payment`, params, {}, false),
  makePayment: (caseId, planId, params) => post(`/arrestee/${caseId}/plan/${planId}/payment`, params, {}, false),
  fetchUrlsEmail: params => post(`/arrestee/forgot`, params, {}, false),
  createNotifyCustomer: params => post(`/arrestee/notify`, params, {}, false),
  fetchDefendantTimeline: (token, type) => get(`/arrestee/${token}/timeline/${type}`, {}, false),
  saveTimelineNote: (token, type, params) => post(`/arrestee/${token}/timeline/${type}`, params, {}, false),
  confirmQuote: token => put(`/arrestee/${token}/confirm_quote`, {}, {}, false),
  confirmPaymentPlan: token => put(`/arrestee/${token}/confirm_payment_plan`, {}, {}, false),
  fetchQuestionnaire: token => get(`/arrestee/${token}/questionnaire`, {}, false),
  saveQuestionnaireAnswer: (token, params) => post(`/arrestee/${token}/questionnaire`, params, {}, false),
  markQuestionaireFinished: token => post(`/arrestee/${token}/questionnaire/complete`, {}, {}, false),
  requestAlternateUpload: token => post(`/arrestee/${token}/upload/alternate`, {}, {}, false),
  fetchCheckin: token => get(`/arrestee/${token}/checkin`, {}, false),
  fetchCheckinFile: (token, key) => post(`/arrestee/${token}/checkin/file`, { key }, {}, false),
  agreeToTerms: (token, type) => put(`/arrestee/${token}/terms`, { type }, {}, false),
  saveProfileInformation: (token, data) => put(`/arrestee/${token}/info`, data, {}, false),
  updateAppCode: token => put(`/arrestee/${token}/code`, {}, {}, false),
  updateProfileView: token => put(`/arrestee/${token}/profile`, {}, {}, false),
  fetchEmailToken: (token, params) => put(`/arrestee/${token}/email-token`, params, {}, false),
  fetchBondsmanByCounty: countyId => get(`/arrestee/county/${countyId}/bondsmen`, {}, false),
  createCase: params => post(`/user/v2/register`, params, {}, false)
}

const auth = {
  login: params => post(`/auth/login`, params),
  register: params => post(`/auth/register`, params),
  forgot: params => post(`/auth/request-password-reset`, params),
  reset: params => post(`/auth/reset-password`, params),
  change: params => post(`/auth/change-password`, params, {}, true)
}

const bondsman = {
  auth: ({ token, invite }) => post(`/bondsman/auth`, { invite }, { 'Authorization': 'Bearer ' + token }, false),
  udpateBondsman: (bondsmanId, params) => put(`/bondsman/${bondsmanId}`, params, {}, true),
  fetchSignedUrl: (params, isSecure = false) => post(`/bondsman/file/url?secure=${isSecure}`, params, {}, true),
  updateEmployeeSettings: params => put(`/bondsman/employee/settings`, params, {}, true),
  saveCard: params => post(`/bondsman/account/card`, params, {}, true),
  saveUploadedFiles: params => post(`/bondsman/file`, params, {}, true),
  createNewCase: params => post(`/bondsman/case`, params, {}, true),
  fetchBondsmanByName: (slug, isEmployee) => get(`/bondsman/${slug}?isEmployee=${isEmployee}`, {}, false),
  fetchBondsman: extended => get(`/bondsman${extended ? '?extended=true' : ''}`, {}, true),
  search: ({ keyword, limit = 5 }) => get(`/bondsman/customer/search?keyword=${keyword}&limit=${limit}`, {}, true),
  fetchCustomers: ({ limit, page, filterStr }) => get(`/bondsman/customers?limit=${limit}&page=${page}${filterStr ? '&' + filterStr : ''}`, {}, true),
  fetchDeletedCustomers: (search = null) => get(`/bondsman/customers/deleted${search ? '?search=' + search.toLowerCase() : ''}`, {}, true),
  fetchCustomersSnapshot: (type = 'STANDARD') => get(`/bondsman/customers/snapshot?type=${type}`, {}, true),
  fetchCustomer: id => get(`/bondsman/customer/${id}`, {}, true),
  fetchFiles: () => get(`/bondsman/files`, {}, true),
  fetchEmployees: (types = '') => get(`/bondsman/employees?type=${types}`, {}, true),
  fetchFilesSchema: () => get(`/bondsman/files/schema`, {}, true),
  updateFile: (id, params) => put(`/bondsman/file/${id}`, params, {}, true),
  syncTemplateData: id => put(`/bondsman/file/${id}/sync`, {}, {}, true),
  fetchBondsmanCounties: () => get(`/bondsman/counties`, {}, true),
  saveBondsmanCounty: params => post(`/bondsman/county`, params, {}, true),
  removeBondsmanCounty: id => remove(`/bondsman/county/${id}`, {}, {}, true),
  updateProfile: params => put(`/bondsman`, params, {}, true),
  updateProfileSeo: params => put(`/bondsman/seo`, params, {}, true),
  updateNotificationSettings: params => put(`/bondsman/settings/notifications`, params, {}, true),
  updateProfileSettings: params => put(`/bondsman/settings`, params, {}, true),
  updatePermissions: params => put(`/bondsman/permissions`, params, {}, true),
  updatePermissionsEmployees: params => put(`/bondsman/permissions/employees`, params, {}, true),
  verifyAccountSetup: params => put(`/bondsman/verify`, params, {}, true),
  fetchBondsmanProcessors: () => get(`/bondsman/processors`, {}, true),
  updateCourthouseInfo: (id, params) => put(`/bondsman/customer/${id}/courthouse`, params, {}, true),
  deleteCaseCourtDate: (id, courtDateId) => remove(`/bondsman/customer/${id}/courthouse/${courtDateId}`, {}, {}, true),
  toggleAppeared: (id, courthouseId) => put(`/bondsman/customer/${id}/courthouse/${courthouseId}/appeared`, {}, {}, true),
  updateCheckinInfo: (id, params) => put(`/bondsman/customer/${id}/checkin`, params, {}, true),
  deleteCheckinInfo: id => remove(`/bondsman/customer/${id}/checkin`, {}, {}, true),
  updateCheckinAddresses: (id, params) => put(`/bondsman/customer/${id}/checkin/addresses`, params, {}, true),
  sendInOfficeLink: (id, params) => post(`/bondsman/customer/${id}/office/request`, params, {}, true),
  migrateContentToWarehouse: token => put(`/bondsman/customer/${token}/migrate`, {}, {}, true),
  toggleManagerStatus: id => put(`/bondsman/customer/${id}/manager_status`, {}, {}, true),
  moveIndemnitor: (id, params) => put(`/bondsman/customer/${id}/move_indemnitor`, params, {}, true),
  updateBookingInfo: (id, params) => put(`/bondsman/customer/${id}/booking`, params, {}, true),
  setDefendantAssignee: (id, params) => put(`/bondsman/customer/${id}/employee`, params, {}, true),
  processFinances: id => put(`/bondsman/customer/${id}/process`, {}, {}, true),
  acceptCashPayment: (id, params) => put(`/bondsman/customer/${id}/cashout`, params, {}, true),
  acceptCase: id => put(`/bondsman/customer/${id}/accept_case`, {}, {}, true),
  deleteCashPayment: (id, params) => remove(`/bondsman/customer/${id}/payment`, params, {}, true),
  updateCaseOffice: (id, params) => put(`/bondsman/customer/${id}/office`, params, {}, true),
  updateForfeitureStatus: (id, params) => put(`/bondsman/customer/${id}/forfeiture/status`, params, {}, true),
  updateForfeiture: (id, params) => put(`/bondsman/customer/${id}/forfeiture`, params, {}, true),
  autoSaveInfo: (id, params) => put(`/bondsman/customer/${id}/autosave`, params, {}, true),
  requestApproval: id => put(`/bondsman/customer/${id}/approval`, {}, {}, true),
  adjustAndNotify: (id, params) => put(`/bondsman/customer/${id}/adjustment`, params, {}, true),
  adjustFinances: (id, params) => put(`/bondsman/customer/${id}/finances`, params, {}, true),
  adjustMetaData: (id, params) => put(`/bondsman/customer/${id}/meta`, params, {}, true),
  initializeCase: id => put(`/bondsman/customer/${id}/initialize`, {}, {}, true),
  updateInfoPacket: (id, params) => put(`/bondsman/customer/${id}/packet`, params, {}, true),
  resetInformation: (id, token) => put(`/bondsman/customer/${id}/packet/reset`, { token }, {}, true),
  updateDocuments: (id, params) => put(`/bondsman/customer/${id}/packet/documents`, params, {}, true),
  adjustCollateral: (id, packetId, params) => put(`/bondsman/customer/${id}/packet/${packetId}/collateral`, params, {}, true),
  toggleAsDefendant: (id, packetId) => put(`/bondsman/customer/${id}/packet/${packetId}/defendant`, {}, {}, true),
  savePacketInfo: (id, params) => put(`/bondsman/customer/${id}/packet/info`, params, {}, true),
  updateStatus: (id, params) => put(`/bondsman/customer/${id}/status`, params, {}, true),
  setCaseStatus: (id, params) => put(`/bondsman/customer/${id}/case_status`, params, {}, true),
  addAdditionalIndemnitor: (id) => post(`/bondsman/customer/${id}/indemnitor`, {}, {}, true),
  deleteIndemnitor: (id) => remove(`/bondsman/customer/${id}/indemnitor`, {}, {}, true),
  resendUniqueUrl: (id) => post(`/bondsman/customer/${id}/resend`, {}, {}, true),
  resetDocument: (id, params) => put(`/bondsman/customer/${id}/document/reset`, params, {}, true),
  getCombinedDocuments: (id, params = {}) => post(`/bondsman/customer/${id}/documents`, params, {}, true),
  getSecureUploadsUrl: id => get(`/bondsman/customer/${id}/uploads`, {}, true),
  getSecureFileUrls: id => get(`/bondsman/customer/${id}/upload/files`, {}, true),
  clientDecision: (id, params) => put(`/bondsman/customer/${id}/decision`, params, {}, true),
  archiveCase: id => put(`/bondsman/customer/${id}/archive`, {}, {}, true),
  deleteCase: id => remove(`/bondsman/customer/${id}`, {}, {}, true),
  reinstateCase: id => put(`/bondsman/customer/${id}/reinstate`, {}, {}, true),
  requestPayment: id => post(`/bondsman/customer/${id}/request_payment`, {}, {}, true),
  messageReceivedTimeline: (id, type) => put(`/bondsman/customer/${id}/timeline/${type.toLowerCase()}/received`, {}, {}, true),
  fetchTimeline: (id, type) => get(`/bondsman/customer/${id}/timeline/${type.toLowerCase()}`, {}, true),
  saveTimelineNote: (id, type, params) => post(`/bondsman/customer/${id}/timeline/${type.toLowerCase()}`, params, {}, true),
  deleteTimelineNote: (id, type, params) => remove(`/bondsman/customer/${id}/timeline/${type.toLowerCase()}`, params, {}, true),
  fetchCustomerQuestionnaire: id => get(`/bondsman/customer/${id}/questionnaire`, {}, true),
  resetQuestionnaire: id => put(`/bondsman/customer/${id}/questionnaire/reset`, {}, {}, true),
  bypassUploads: (id, type) => put(`/bondsman/customer/${id}/bypass_uploads`, { type }, {}, true),
  resetUploads: (id, type) => put(`/bondsman/customer/${id}/uploads/reset`, { type }, {}, true),
  issueRefund: id => put(`/bondsman/customer/${id}/refund`, {}, {}, true),
  updateCustomFields: (id, params) => put(`/bondsman/customer/${id}/custom_fields`, params, {}, true),
  saveBondsmanCountyRate: (id, params) => put(`/bondsman/county/${id}/rate`, params, {}, true),
  updateBondsmanLink: params => put(`/bondsman/link`, params, {}, true),
  fetchUsers: () => get(`/bondsman/users`, {}, true),
  fetchApplicationInfo: () => get(`/bondsman/application`, {}, true),
  saveApplicationInfo: params => post(`/bondsman/application`, params, {}, true),
  submitCompletedApplication: () => put(`/bondsman/application/complete`, {}, {}, true),
  quickAddUser: params => post(`/bondsman/application/add`, params, {}, true),
  fetchQuestions: () => get(`/bondsman/questionair`, {}, true),
  addQuestion: params => post(`/bondsman/questionair`, params, {}, true),
  upateQuestion: (questionId, params) => put(`/bondsman/questionair/${questionId}`, params, {}, true),
  removeQuestion: questionId => remove(`/bondsman/questionair/${questionId}`, {}, {}, true),
  saveQuestionairOrder: params => put(`/bondsman/questionair/order`, params, {}, true),
  markCaseClosed: id => put(`/bondsman/customer/${id}/close`, {}, {}, true),
  fetchCheckinFile: (id, key) => post(`/bondsman/customer/${id}/checkin/file`, { key }, {}, true),
  generateInvoice: id => post(`/bondsman/customer/${id}/invoice`, {}, {}, true),
  fetchCaseCheckinDocInfo: (agencyId, caseId) => getCheckins(`/info/${agencyId}/case/${caseId}`, {}, false),
  upateAlertSettings: params => put(`/bondsman/alert/settings`, params, {}, true),
  fetchAlertSettings: () => get(`/bondsman/alert/settings`, {}, true),
  fetchLearningCenterVideos: () => get(`/learningcenter/videos`, {}, true)
}

const cases = {
  processOpenAiRequest: (data) => post(`/openai`, data, {}, true),
  fetchBrief: (caseId, params) => post(`/case/${caseId}/brief`, params, {}, true),
  fetchDefendants: () => get(`/defendants`, {}, true),
  fetchExpiringCases: (options=null) => get(`/cases/expiring${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchDefendantById: id => get(`/defendant/${id}`, {}, true),
  fetchCase: id => get(`/case/${id}`, {}, true),
  updateCase: (id, params) => put(`/case/${id}`, params, {}, true),
  sendTextMessage: (caseID, params) => post(`/case/${caseID}/text`, params, {}, true),
  createCase: params => post(`/case`, params, {}, true),
  reOpenCase: (caseID) => put(`/case/${caseID}/re-open`, {}, {}, true),
  addReferral: (caseID, params) => post(`/case/${caseID}/referral`, params, {}, true),
  addIndemnitor: (caseID, params) => post(`/case/${caseID}/indemnitor`, params, {}, true),
  setupTestConditions: (caseID, params) => put(`/case/${caseID}/test`, params, {}, true),
  updateDefendant: (caseID, params) => put(`/case/${caseID}/defendant`, params, {}, true),
  revealText: (caseID, params) => post(`/case/${caseID}/reveal`, params, {}, true),
  sendGeneralNotification: (caseID, infoId) => put(`/case/${caseID}/info/${infoId}/notify`, {}, {}, true),
  swapPrimaryIndemnitor: (caseID, infoId) => put(`/case/${caseID}/info/${infoId}/swap`, {}, {}, true),
  addCardPayment: (caseID, params) => post(`/case/${caseID}/payment`, params, {}, true),
  convertLead: (caseID, params) => put(`/lead/${caseID}/convert`, params, {}, true),
  fetchMessagingLogs: (caseID, phoneNumber) => get(`/case/${caseID}/phone/${phoneNumber}/logs`, {}, true),
  fetchPowerInfo: (powerIds, options = null) => post(`/case/power-info${options ? buildQueryStringFromJSON(options) : ''}`, { powerIds }, {}, true),
  searchCases: options => get(`/case/search${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  searchIndemnitors: defendantId => get(`/case/search/${defendantId}/indemnitors`, {}, true),
  fetchLinkedCases: caseID => get(`/case/${caseID}/linked-cases`, {}, true),
  switchUser: (caseId, infoPacketId, params) => put(`/case/${caseId}/user/${infoPacketId}/switch`, params, {}, true),
  copyInfoPacket: (caseId, infoPacketId, params) => put(`/case/${caseId}/user/${infoPacketId}/copy`, params, {}, true),
  markAreaCompleteManually: (caseId, infoPacketId, params) => put(`/case/${caseId}/user/${infoPacketId}/mark-complete`, params, {}, true),
  fetchCaseStats: (query) => get(`/stats${buildQueryStringFromJSON(query)}`, {}, true),
  resetProfile: (caseId, infoId, data) => put(`/case/${caseId}/info/${infoId}/reset`, data, {}, true),
  fetchLightCheckins: () => get(`/cases/light-checkin`, {}, true),
  toggleLightCheckin: (caseId) => put(`/case/${caseId}/light-checkin`, {}, {}, true),
  doLightCheckin: (caseId) => post(`/case/${caseId}/light-checkin/checkin`, {}, {}, true),
  joinCase: (caseId, params) => put(`/case/${caseId}/join`, params, {}, true),
  sendGoogleReview: (caseId) => post(`/case/${caseId}/google-review`, {}, {}, true),
  verifyPostPayment: (caseId) => post(`/case/${caseId}/post-verify`, {}, {}, true),
  addCaseLog: (caseId, data) => post(`/case/${caseId}/log`, data, {}, true),
  toggleNotifications: (caseId, infoPacketId) => put(`/case/${caseId}/user/${infoPacketId}/notifications`, {}, {}, true),
  processPowerRequest: (caseId, data) => post(`/case/${caseId}/power-request`, data, {}, true)
}

const checkins = {
  missing: () => get(`/checkins/missing`, {}, true),
  snapshot: (agencyId, date) => getCheckins(`/checkins/${agencyId}/snapshot?dates=${date}`, {}, true),
  all: (agencyId, options) => getCheckins(`/checkins/${agencyId}/all${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetch: (agencyId, checkinId) => getCheckins(`/checkins/${agencyId}/checkin/${checkinId}`, {}, false),
  logLocation: (caseId, params) => postCheckins(`/checkin/${caseId}/location`, params, {}, false),
  create: (params) => post(`/checkin`, params, {}, true),
  sendCheckinAlert: id => post(`/checkin/${id}/alert`, {}, {}, true),
  sendMissedCheckinAlerts: date => post(`/checkin/alerts?date=${date}`, {}, {}, true),
  saveCheckinSettings: data => put(`/checkin/settings`, data, {}, true),
  deleteCheckin: id => remove(`/checkin/${id}`, {}, {}, true),
  sendNotification: (checkinId, data) => post(`/checkin/${checkinId}/notification`, data, {}, true), // LIVE
  saveAppSettings: (checkinId, data) => put(`/checkin/${checkinId}/app/settings`, data, {}, true) // LIVE
}

const clock = {
  fetchAll: () => get(`/clocks/all`, {}, true),
  fetchCurrent: () => get(`/clock/current`, {}, true),
  toggleEvent: () => post(`/clock`, {}, {}, true),
  updateEvent: (clockId, params) => put(`/clock/${clockId}`, params, {}, true),
  addAdminEvent: (params) => post(`/clock/admin`, params, {}, true),
  removeEvent: (clockId) => remove(`/clock/${clockId}`, {}, {}, true)
}

const counties = {
  fetchCounties: state => get(`/counties?state=${state}`, {}, false),
  searchCounties: search => get(`/counties/search?search=${search}`, {}, false)
}

const courthouses = {
  all: (agencyId, options) => getCheckins(`/courthouses/${agencyId}/all${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchCaseCourtdates: (agencyId, caseId) => getCheckins(`/courthouses/${agencyId}/courthouse/${caseId}`, {}, true),
  assignCourtDate: (caseId, appearanceId, powerId) => put(`/case/${caseId}/appearance/${appearanceId}`, { powerId }, {}, true)
}

const defendant = {
  fetch: token => get(`/defendant/${token}`, {}, false),
  confirmTerms: token => put(`/defendant/${token}/terms`, {}, {}, false),
  getDefendantDocumentStatus: token => get(`/defendant/${token}/documents/status`, {}, false),
  fetchDefendantBondSeeker: token => get(`/defendant/${token}/seeker`, {}, false)
}

const documents = {
  fetchAllUserDocumentStatus: token => get(`/document/all`, { headers: { 'X-User-Token': token } }, false),
  fetchUserDocuments: token => get(`/document`, { headers: { 'X-User-Token': token } }, false),
  fetchDefendantDocuments: token => get(`/document/defendant`, { headers: { 'X-Defendant-Token': token } }, false),
  updateUserDocuments: (token, params) => put(`/document`, params, { 'X-User-Token': token }, false),
  updateDefendantDocuments: (token, params) => put(`/document/defendant`, params, { 'X-Defendant-Token': token }, false),
  fetchDocumentsUrl: (token, params, formBuilder = false) => {
    let url = `/document/url`
    if (formBuilder) url = `/document/url?manual=1`
    return post(url, params, { 'X-User-Token': token }, false)
  },
  // fetchDocumentsUrlDefendant: (token, params) => post(`/document/url/defendant`, params, { 'X-User-Token': token }, false),
  updateDocumentSubmission: (token, params, siToken = null) => {
    const headers = { 'X-User-Token': token }
    if (siToken) headers['X-User-SI-Token'] = siToken
    return put(`/document/submission`, params, headers, false)
  },
  updateDocumentSubmissionDefendant: (token, params) => put(`/document/submission/defendant`, params, { 'X-Defendant-Token': token }, false),
  fetchSampleData: isDefendant => get(`/document/sample/data${(isDefendant) ? '?defendant=true' : ''}`, {}, false), // In Form Builder
  fetchDeveloperData: () => get(`/document/developer/data`, {}, false) // In Process
}

const employees = {
  all: (query = null) => get(`/employees${query ? query : ''}`, {}, true),
  createEmployee: data => post(`/employee`, data, {}, true),
  updateEmployee: (id, data) => put(`/employee/${id}`, data, {}, true),
  resetEmployee: (id) => put(`/employee/${id}/reset`, {}, {}, true),
  deleteEmployee: (id) => remove(`/employee/${id}`, {}, {}, true),
  fetchEmployeeByToken: token => get(`/employee/token/verify`, { headers: { 'X-Reset-Token': token } }, false),
  resetPassword: (token, data) => put(`/employee/password-reset`, data, { 'X-Reset-Token': token }, false)
}

const explorer = {
  fetch: id => get(`/explorer/${id}`, {}, true),
  fetchPrintables: id => get(`/explorer/${id}/printables`, {}, true),
  updateMetaData: (id, params) => put(`/explorer/${id}/file/meta`, params, {}, true),
  deleteFile: (id, fileId, key) => remove(`/explorer/${id}/file/${fileId}`, { key }, {}, true),
  sendRequest: (id, params) => post(`/explorer/${id}/request`, params, {}, true),
  generatePrintableForm: (id, params) => post(`/explorer/${id}/printable`, params, {}, true),
  savePostBailDocument: (id, requestId, params) => post(`/explorer/${id}/request/${requestId}/pdf`, params, {}, true),
  fetchRequest: token => get(`/explorer/request`, { headers: { 'X-Request-Token': token } }, false),
  getUrlFormRequest: id => get(`/explorer/request/${id}/form`, {}, false),
  markRequestComplete: id => put(`/explorer/request/${id}/complete`, {}, {}, false),
  deleteRequest: (id, requestId) => remove(`/explorer/${id}/request/${requestId}`, {}, {}, true),
  printCombinedDocument: (id, params) => post(`/explorer/${id}/print`, params, {}, true),
  generateReceipts: (id) => post(`/explorer/${id}/receipts`, {}, {}, true),
  saveCCAuthForm: (id, params) => post(`/explorer/${id}/cc-auth-form`, params, {}, false)
}

const files = {
  fetchTokenizedUrl: (token, text) => post(`/files/url?text=${text ? 1 : 0}`, {}, { 'X-User-Token': token }, false),
  fetchSignedUrl: (token, params = {}) => post(`/files/upload`, params, { 'X-Upload-Token': token }, false),
  fetchSignedUrlPost: (token, params = {}) => post(`/files/upload?status=store`, params, { 'X-Upload-Token': token }, false),
  fetchUploads: token => get(`/files`, { headers: { 'X-Upload-Token': token } }, false),
  fetchSecureUrls: (token, params) => post(`/files/upload/urls`, params, { 'X-Upload-Token': token }, false),
  updateDocumentsUploaded: (token, params) => put(`/files/upload`, params, { 'X-Upload-Token': token }, false),
  fileUploadComplete: (token, params) => put(`/files/upload/complete`, params, { 'X-Upload-Token': token }, true)
}

const forfeitures = {
  all: (options = null) => get(`/forfeitures${buildQueryStringFromJSON(options)}`, {}, true),
  fetchLiability: (query = null) => get(`/forfeitures/liability${query ? query : ''}`, {}, true),
  fetchForfeituresForCaseId: (caseId) => get(`/forfeitures/case/${caseId}`, {}, true),
  removeForfeiture: id => remove(`/forfeitures/${id}`, {}, {}, true),
  assign: (id, courtAppearanceId) => put(`/forfeitures/${id}/assign`, { courtAppearanceId }, {}, true),
  assignEmployee: (id, employeeId) => put(`/forfeitures/${id}/employee`, { employeeId }, {}, true),
  addStatus: (id, data) => put(`/forfeitures/${id}/status`, data, {}, true),
  removeStatus: (id, statusId) => remove(`/forfeitures/${id}/status/${statusId}`, {}, {}, true)
}

const importer = {
  fetchImportStats: () => get(`/import/stats`, {}, true),
  fetchImportSettings: () => get(`/import/settings`, {}, true),
  updateImportSettings: (data) => put(`/import/settings`, data, {}, true),
  fetchImportById: id => get(`/import/${id}`, {}, true),
  importData: params => post(`/import`, params, {}, true),
  importServiceData: params => post(`/import/service`, params, {}, true),
  publishImport: importId => put(`/import/${importId}/publish`, {}, {}, true),
  publishAll: () => put(`/import/publish/all`, {}, {}, true),
  deleteAll: () => remove(`/import/delete/all`, {}, {}, true),
  fetchRecords: (options = null) => get(`/imports${options ? buildQueryStringFromJSON(options) : ''}`, {}, true)
}

const finance = {
  fetchSnapshot: () => get(`/finance/snapshot`, {}, true),
  fetchSettlements: (options = null) => get(`/finance/settlements${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchTransactions: (options = null) => get(`/finance/transactions${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchPayments: (options = null) => get(`/finance/payments${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchRefunds: (options = null) => get(`/finance/refunds${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchInvoices: () => get(`/finance/invoices`, {}, true),
  fetchInvoice: id => get(`/finance/invoice/${id}`, {}, true),
  findUserTransactions: searchText => post(`/finance/refund/search`, { searchText }, {}, true),
  issueRefund: params => post(`/finance/refund/issue`, params, {}, true),
  issueIndividualRefund: (id, params) => post(`/finance/refund/issue/${id}`, params, {}, true),
  fetchSettlementById: id => get(`/finance/settlement/${id}`, {}, true),
  attachPaymentToCase: params => post(`/finance/payment/associate`, params, {}, true),
  voidPayment: (caseId, paymentId) => put(`/finance/${caseId}/void/${paymentId}`, {}, {}, true),
  fetchFunds: (options) => get(`/finance/funds${buildQueryStringFromJSON(options)}`, {}, {}, true),
  fetchPaymentsByStatus: (options = null) => get(`/finance/payments/status${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  updatePaymentStatus: (paymentId, status) => put(`/finance/payment/${paymentId}/status`, { status }, {}, true),
  fetchDailyPayments: (options = null) => get(`/finance/widget${buildQueryStringFromJSON(options)}`, {}, true),
  deleteImportedPayment: (paymentId) => remove(`/finance/payment/${paymentId}`, {},  {}, true)
}

const inventory = {
  searchInventory: (agencyId, options) => getInventory(`/agency/${agencyId}/search${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  searchPackages: (agencyId, options) => getInventory(`/agency/${agencyId}/packages/search${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  addOrUpdateContract: (agencyId, params) => postInventory(`/agency/${agencyId}/contract`, params, {}, true),
  removeContract: (agencyId, contractId, params = {}) => removeInventory(`/agency/${agencyId}/contract/${contractId}`, params, {}, true),
  fetchAgency: agencyId => getInventory(`/agency/${agencyId}`, {}, true),
  createAgency: (agencyId, params) => postInventory(`/agency/${agencyId}`, params, {}, true),
  updatePower: (agencyId, powerId, params) => putInventory(`/agency/${agencyId}/power/${powerId}/power`, params, {}, true),
  fetchSuretys: () => getInventory(`/surety/all`, {}, true),
  fetchSuretyContracts: () => getInventory(`/surety/contracts`, {}, true),
  fetchEmployeePowers: (employeeId, officeId = '') => getInventory(`/employee/${employeeId}/powers?office=${officeId}`, {}, true),
  syncEmployees: (agencyId, params) => postInventory(`/agency/${agencyId}/employees/sync`, params, {}, true),
  fetchEmployees: agencyId => getInventory(`/agency/${agencyId}/employees`, {}, true),
  fetchPowersById: (suretyId, powerId) => getInventory(`/surety/${suretyId}/power/${powerId}`, {}, true),
  fetchSnapshot: (agencyId, options = null) => getInventory(`/agency/${agencyId}/snapshot${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchPackages: agencyId => getInventory(`/agency/${agencyId}/packages`, {}, true),
  deletePackage: (agencyId, packageId) => removeInventory(`/agency/${agencyId}/package/${packageId}`, {}, {}, true),
  fetchPackageById: (agencyId, packageId) => getInventory(`/agency/${agencyId}/package/${packageId}`, {}, true),
  fetchPackagePowers: (agencyId, packageId) => getInventory(`/agency/${agencyId}/package/${packageId}/powers`, {}, true),
  fetchEntityPowers: (agencyId, type, id) => getInventory(`/agency/${agencyId}/entity/${type}/${id}/powers`, {}, true),
  distributePowers: (agencyId, type, id, params) => putInventory(`/agency/${agencyId}/entity/${type}/${id}/distribute`, params, {}, true),
  createPackage: (agencyId, params) => postInventory(`/agency/${agencyId}/package`, params, {}, true),
  fetchDistribution: agencyId => getInventory(`/agency/${agencyId}/powers/distribute`, {}, true),
  assignDistribution: (agencyId, params) => putInventory(`/agency/${agencyId}/powers/assign`, params, {}, true),
  updateInventory: (agencyId, params) => putInventory(`/agency/${agencyId}/powers`, params, {}, true),
  savePowers: (agencyId, params) => postInventory(`/agency/${agencyId}/powers`, params, {}, true),
  fetchAgencyPowers: (agencyId, status = 'OPEN') => getInventory(`/agency/${agencyId}/powers?status=${status}`, {}, true),
  fetchAgencyLiability: (agencyId) => getInventory(`/agency/${agencyId}/liability`, {}, true),
  fetchCasePowers: (agencyId, caseId) => getInventory(`/agency/${agencyId}/powers/${caseId}`, {}, true),
  deletePower: (agencyId, powerId) => removeInventory(`/agency/${agencyId}/power/${powerId}`, {}, {}, true),
  searchPowers: (agencyId, options = {}) => getInventory(`/agency/${agencyId}/powers/inventory/search${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchPowersByType: (agencyId, type, options = null) => get(`/agency/${agencyId}/powers/${type}${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchAllPowers: (agencyId, options = null) => getInventory(`/agency/${agencyId}/powers/all${options ? buildQueryStringFromJSON(options) : ''}`, {}, {}, true),
  updatePowerStatus: (agencyId, powerId, status) => put(`/agency/${agencyId}/power/${powerId}/status`, { status }, {}, true),
  fetchReports: (agencyId, suretyId, options = null) => getInventory(`/agency/${agencyId}/surety/${suretyId}/reports${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchReportById: (agencyId, reportId) => getInventory(`/agency/${agencyId}/report/${reportId}`, {}, true),
  updateReport: (suretyId, reportId, params) => putInventory(`/surety/${suretyId}/report/${reportId}`, params, {}, true),
  updateReportPowers: (agencyId, reportId, params) => putInventory(`/agency/${agencyId}/report/${reportId}/update-powers`, params, {}, true),
  fileExecutionReport: (agencyId, params) => postInventory(`/agency/${agencyId}/report`, params, {}, true),
  fetchReportDownloadUrl: (agencyId, reportId) => get(`/agency/${agencyId}/report/${reportId}`, {}, true),
  deleteReport: (agencyId, reportId) => removeInventory(`/agency/${agencyId}/report/${reportId}`, {}, {}, true),
  completeReport: (agencyId, reportId) => putInventory(`/agency/${agencyId}/report/${reportId}/complete`, {}, {}, true),
  fetchUnassignedPowers: (agencyId, options = null) => get(`/agency/${agencyId}/powers/unassigned${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  cleanupUnassignedPowers: (agencyId) => put(`/agency/${agencyId}/powers/unassigned/cleanup`, {}, {}, true),
  fetchTransfers: (agencyId) => getInventory(`/agency/${agencyId}/transfers`, {}, true),
  takeOwnership: (agencyId, transferId, params) => putInventory(`/agency/${agencyId}/transfer/${transferId}/claim`, params, {}, true),
  fetchManagedUsers: () => getInventory(`/connector/managed-users`, {}, true),
  setManagedUser: (agencyId, suretyId, params) => postInventory(`/connector/${agencyId}/managed-user`, { ...params, suretyId }, {}, true),
  connectorLogin: (agencyId, suretyId, params) => postInventory(`/connector/${agencyId}/surety/${suretyId}/login`, params, {}, true),
  fetchPaymentAccounts: (agencyId, reportId) => getInventory(`/connector/${agencyId}/report/${reportId}/payment-accounts`, {}, true),
  fetchConnections: agencyId => getInventory(`/connector/${agencyId}/suretys`, {}, true),
  publishDocument: (agencyId, reportId, powerId) => postInventory(`/connector/${agencyId}/report/${reportId}/power/${powerId}/document`, {}, {}, true),
  publishReport: (agencyId, reportId, data) => postInventory(`/connector/${agencyId}/report/${reportId}/publish`, data, {}, true),
  saveAgentMapping: (agencyId, suretyId, data) => putInventory(`/connector/${agencyId}/surety/${suretyId}/settings`, data, {}, true),
  removePowerFromReport: (agencyId, reportId, powerId) => removeInventory(`/connector/${agencyId}/report/${reportId}/power/${powerId}`, {}, {}, true),
  saveReportMetadata: (agencyId, suretyId, powerId, data) => putInventory(`/connector/${agencyId}/surety/${suretyId}/power/${powerId}/metadata`, data, {}, true),
  fetchReportPowerDetails: (agencyId, suretyId, reportId, powerIds) => putInventory(`/connector/${agencyId}/surety/${suretyId}/report/${reportId}/powers`, { powerIds }, {}, true),
  fetchExecutionReports: (agencyId, suretyId) => getInventory(`/connector/${agencyId}/surety/${suretyId}/reports`, {}, true),
  fetchUnexecutedPowers: (agencyId, suretyId) => getInventory(`/connector/${agencyId}/surety/${suretyId}/unexecuted`, {}, true),
  importUnexecuted: (agencyId, suretyId, params) => putInventory(`/connector/${agencyId}/surety/${suretyId}/unexecuted/import`, params, {}, true),
  reinstatePower: (agencyId, suretyId, powerId, params) => putInventory(`/connector/${agencyId}/surety/${suretyId}/power/${powerId}/reinstate`, params, {}, true),
  dischargePower: (agencyId, suretyId, powerId, params) => putInventory(`/connector/${agencyId}/surety/${suretyId}/power/${powerId}/discharge`, params, {}, true),
  fetchCities: (agencyId, suretyId, stateAbbr, countyName) => getInventory(`/connector/${agencyId}/surety/${suretyId}/cities/${stateAbbr}/${countyName}`, {}, true),
  createTransfer: (agencyId, params) => postInventory(`/agency/${agencyId}/transfer`, params, {}, true),
  fetchInternalTransferPowers: (agencyId, transferId) => getInventory(`/agency/${agencyId}/transfer/${transferId}/powers`, {}, true),
  deleteTransfer: (agencyId, transferId) => removeInventory(`/agency/${agencyId}/transfer/${transferId}`, {}, {}, true),
  updateReportStatus: (agencyId, reportId, status) => putInventory(`/surety/${agencyId}/report/${reportId}/status`, { status }, {}, true),
  fetchForfeitures: (agencyId, options = {}) => getInventory(`/surety/${agencyId}/statuses${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchFilesForKey: (suretyId, key) => getInventory(`/surety/${suretyId}/files?key=${key}`, {}, true),
  fetchStatus: (id, statusId) => getInventory(`/surety/${id}/status/${statusId}`, {}, true),
  addStatus: (id, params) => postInventory(`/surety/${id}/status`, params, {}, true),
  updateStatus: (id, statusId, params) => putInventory(`/surety/${id}/status/${statusId}`, params, {}, true),
  deleteStatus: (id, statusId) => removeInventory(`/surety/${id}/status/${statusId}`, {}, {}, true),
  addSubStatus: (suretyId, statusId, params) => postInventory(`/surety/${suretyId}/status/${statusId}/sub-status`, params, {}, true),
  updateSubStatus: (suretyId, statusId, subStatusId, params) => putInventory(`/surety/${suretyId}/status/${statusId}/sub-status/${subStatusId}`, params, {}, true),
  removeSubStatus: (suretyId, statusId, subStatusId) => removeInventory(`/surety/${suretyId}/status/${statusId}/sub-status/${subStatusId}`, {}, {}, true)
}

const link = {
  fetch: linkId => get(`/link/${linkId}`, {}, false)
}

const invoice = {
  deleteInvoice: invoiceId => remove(`/invoice/${invoiceId}`, {}, {}, true),
  bypassPayments: (invoiceId, params) => put(`/invoice/${invoiceId}/bypass`, params, {}, true),
  processPayments: invoiceId => put(`/invoice/${invoiceId}/process`, {}, {}, true)
}

const logs = {
  fetch: caseId => getLogs(`/logs/${caseId}`, {}, true),
  fetchLocationLogs: (caseId, date) => getLogs(`/logs/${caseId}/location?dateFrom=${date.from}&dateTo=${date.to}`, {}, true),
  fetchLastLocationInfo: caseId => getLogs(`/logs/${caseId}/location/last`, {}, true)
}

const lookup = {
  searchCounties: state => getLookup(`/counties?state=${state}`, {}, false),
  victimSearch: params => postLookup(`/search`, params, {}, false),
  victimSearchDetail: params => postLookup(`/search/detail`, params, {}, false),
  ebondsSearch: (tenantId, { nameFirst, nameLast }) => get(`/ebonds/tenant/${tenantId}/inmate?nameFirst=${nameFirst}&nameLast=${nameLast}`, {}, true),
  ebondsInmateDetail: (tenantId, inmateId) => get(`/ebonds/tenant/${tenantId}/inmate/${inmateId}`, {}, true)
}

const onboarding = {
  signup: params => post(`/fupa/bondsman`, params, {}, false),
  fetchBondsman: token => {
    const isLoggedIn = window.localStorage.getItem('token')
    if (!isLoggedIn) {
      return get(`/onboarding/bondsman/${token}/login`, {}, false)
    }
    return get(`/onboarding/bondsman/${token}`, {}, true)
  },
  changePassword: ({ token, password }) => put(`/onboarding/password`, { password }, { 'Agent-Token': token }, false),
  saveOnboardingInfo: (token, data) => post(`/onboarding/bondsman/${token}`, data, {}, true),
  fetchApplication: applicationId => get(`/onboarding/application/${applicationId}`, {}, true),
  saveOnboardingInfoAdditional: (token, data) => post(`/onboarding/bondsman/${token}/additional`, data, {}, true),
  generateApplication: token => post(`/onboarding/bondsman/${token}/application`, {}, {}, true),
  updateOnboardingPassword: (token, params) => put(`/onboarding/bondsman/${token}/password`, params, {}, true)
}

const offices = {
  searchOffices: (options = {}) => get(`/offices/search${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchOfficeInfo: () => get(`/offices/info`, {}, true),
  fetchOffices: () => get(`/offices`, {}, true),
  fetchOfficeById: (officeId) => get(`/office/${officeId}`, {}, true),
  saveOffice: params => post(`/office`, params, {}, true),
  updateOffice: (id, params) => put(`/office/${id}`, params, {}, true),
  removeOffice: id => remove(`/office/${id}`, {}, {}, true),
  saveOfficeFile: (id, params) => post(`/office/${id}/file`, params, {}, true),
  saveOfficeEmployee: (id, params) => post(`/office/${id}/employee`, params, {}, true),
  saveOfficeLocation: (id, params) => post(`/office/${id}/location`, params, {}, true),
  updateOfficeSetting: (id, params) => put(`/office/${id}/setting`, params, {}, true),
  removeOfficeAttribute: (id, params) => remove(`/office/${id}/attribute`, params, {}, true)
}

const payment_plans = {
  fetchPayments: (options = null) => get(`/payments${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchReporting: query => get(`/payments/reporting${query}`, {}, true),
  fetchPaymentsSnapshot: (options = null) => get(`/payments/snapshot${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchPaymentsByType: options => get(`/payments/type${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchMissingPayments: options => get(`/payments/missing${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchPaymentPlans: (queryStr = '') => get(`/payments/plans${queryStr}`, {}, true),
  fetchPlanById: planId => get(`/payments/plan/${planId}`, {}, true),
  activatePlan: planId => put(`/payments/plan/${planId}/activate`, {}, {}, true),
  ressurectPlan: planId => put(`/payments/plan/${planId}/ressurect`, {}, {}, true),
  fetchPaymentPlansByType: type => get(`/bondsman/payment_plans/${type}`, {}, true),
  fetchPaymentPlan: id => get(`/bondsman/customer/${id}/payment_plan`, {}, true),
  adjustPaymentPlan: (id, params) => put(`/bondsman/customer/${id}/payment_plan`, params, {}, true),
  deletePaymentPlan: id => remove(`/bondsman/customer/${id}/payment_plan`, {}, {}, true),
  cancelPaymentPlan: id => put(`/bondsman/plan/${id}/payment_plan/cancel`, {}, {}, true),
  markPaymentPlanPayoff: id => put(`/bondsman/customer/${id}/payment_plan/payoff`, {}, {}, true),
  updateCard: (id, params) => put(`/bondsman/customer/${id}/payment_plan/update_card`, params, {}, true),
  processPlanPayment: (planId, paymentId) => put(`/bondsman/plan/${planId}/payment/${paymentId}/process`, {}, {}, true),
  markPaymentReceived: (planId, paymentId, amount) => put(`/bondsman/plan/${planId}/payment/${paymentId}/received`, { amount }, {}, true),
  markPaymentReversed: (planId, paymentId) => put(`/payments/plan/${planId}/payment/${paymentId}/reversed`, {}, {}, true),
  editPayment: (planId, paymentId, data) => put(`/payments/plan/${planId}/payment/${paymentId}`, data, {}, true),
  addAdditionalPayments: (planId, data) => post(`/payments/plan/${planId}/payments`, data, {}, true),
  spreadToCurrentPayments: (planId, data) => put(`/payments/plan/${planId}/payments`, data, {}, true),
  deletePayment: (planId, paymentId) => remove(`/payments/plan/${planId}/payment/${paymentId}`, {}, {}, true),
  assignPayment: (planId, params) => put(`/payments/plan/${planId}/assign`, params, {}, true),
  associatePayment: (planId, paymentId, params) => put(`/payments/plan/${planId}/payment/${paymentId}/associate`, params, {}, true),
  associateAdditionalPayment: (planId, paymentId, params) => put(`/payments/plan/${planId}/payment/${paymentId}/associate/additional`, params, {}, true),
  addPayment: (planId, params) => post(`/payments/plan/${planId}/payment`, params, {}, true),
  removeCardOnFile: planId => remove(`/payments/plan/${planId}/card`, {}, {}, true),
  fetchPaymentCaseInfo: (caseIds, params = {}) => post(`/payments/case-info`, { caseIds, params }, {}, true),
  changePlanStatus: (planId, params) => put(`/payments/plan/${planId}/status`, params, {}, true)
}

const powers = {
  fetchCasePowers: caseId => get(`/case/${caseId}/powers`, {}, true),
  fetchPowerDetails: (suretyId, powerNumber) => get(`/surety/${suretyId}/power/info?powerNumber=${encodeURI(powerNumber.trim())}`, {}, true),
  searchPowers: (options = null) => get(`/case/powers/search${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  updatePowerDetails: (caseId, powerId, params) => put(`/case/${caseId}/power/${powerId}/details`, params, {}, true),
  processRewrite: (caseId, powerId, params) => put(`/case/${caseId}/power/${powerId}/rewrite`, params, {}, true),
  removeRewrite: (caseId, powerId) => remove(`/case/${caseId}/power/${powerId}/rewrite`, {}, {}, true),
  saveCasePower: (caseId, params) => post(`/case/${caseId}/power`, params, {}, true),
  updatePower: (caseId, powerId, params) => put(`/case/${caseId}/power/${powerId}`, params, {}, true),
  savePowerFinances: (caseId, powerId, params) => put(`/case/${caseId}/power/${powerId}/finances`, params, {}, true),
  removeCasePower: (caseId, powerId) => remove(`/case/${caseId}/power/${powerId}`, {}, {}, true),
  swapVoidPower: (caseId, powerId, data = {}) => put(`/case/${caseId}/power/${powerId}/swap-void`, data, {}, true),
  executePowers: caseId => put(`/case/${caseId}/execute`, {}, {}, true),
  sendPowerUploadUrl: (caseId, params = {}) => post(`/case/${caseId}/power-upload-url`, params, {}, true),
  fetchPowerProofs: caseId => get(`/case/${caseId}/power-proofs`, {}, true),
  savePowerProof: (caseId, params) => post(`/case/${caseId}/power-proof`, params, {}, true),
  fetchPublishingPowers: (agencyId, powerIds) => post(`/agency/${agencyId}/publishing-powers`, { powerIds }, {}, true),
  savePowerAssignment: (agencyId, powerId, params) => put(`/agency/${agencyId}/power/${powerId}/assign`, params, {}, true),
  fetchPowersByIds: (agencyId, params) => post(`/agency/${agencyId}/powers`, params, {}, true),
  updatePowerLiability: (agencyId, powerId, params) => put(`/agency/${agencyId}/power/${powerId}/liability`, params, {}, true),
  generatePowerTransferSheet: (agencyId, transferId, params) => post(`/agency/${agencyId}/transfer/${transferId}`, params, {}, true),
  fetchTbdInventory: (agencyId, options = null) => get(`/agency/${agencyId}/tbd${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  fetchTransfers: (agencyId, options = null) => get(`/agency/${agencyId}/transfers${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  addTransfer: (caseId, powerId, data) => post(`/case/${caseId}/power/${powerId}/transfer`, data, {}, true),
  addOutgoingTransfer: (data) => post(`/power/transfer/outgoing`, data, {}, true),
  updateOutgoingTransfer: (powerId, data) => put(`/power/transfer/outgoing/${powerId}`, data, {}, true),
  generatePower: (params) => post(`/power/generate`, params, {}, true)
}

const questionnaire = {
  fetch: (type, section) => get(`/bondsman/questions/${type}/${section}`, {}, true),
  save: (type, section, data) => post(`/bondsman/questions/${type}/${section}`, data, {}, true)
}

const referrals = {
  all: () => get(`/referrals`, {}, true),
  fetchByType: (type) => get(`/referrals/${type}`, {}, true),
  create: (type, params) => post(`/referrals/${type}`, params, {}, true) //title template ID payload
  // update: (id, params) => put(`/reporting/quick/${id}`, params, {}, true), // id of the report + create stuff
  // remove: id => remove(`/reporting/quick/${id}`, {}, {}, true) // id of the report
}

const reporting = {
  fetch: () => getReporting(`/reporting`, {}, true),
  fetchDashboardReports: () => getReporting(`/reporting/dashboard`, {}, true),
  dependencies: () => getReporting(`/reporting/dependencies`, {}, true),
  create: params => postReporting(`/reporting/feature`, params, {}, true),
  update: (id, params) => putReporting(`/reporting/feature/${id}`, params, {}, true),
  duplicate: (id, params) => postReporting(`/reporting/feature/${id}/duplicate`, params, {}, true),
  remove: id => removeReporting(`/reporting/feature/${id}`, {}, {}, true),
  build: (id, params, type = 'VIEW') => postReporting(`/reporting/report/${id}?type=${type}`, params, {}, true),
  fetchArReport: (options) => getReporting(`/reporting/ar${options ? buildQueryStringFromJSON(options) : ''}`, {}, true),
  triggerArProcess: () => putReporting(`/reporting/ar/reset`, {}, {}, true),
  fetchArDetail: caseId => getReporting(`/reporting/ar/${caseId}`, {}, true),
  markArComplete: caseId => putReporting(`/reporting/ar/${caseId}/complete`, {}, {}, true)
}

const reports = {
  fetch: () => getReporting(`/reporting/quick`, {}, true),
  create: params => postReporting(`/reporting/quick`, params, {}, true), //title template ID payload
  update: (id, params) => putReporting(`/reporting/quick/${id}`, params, {}, true), // id of the report + create stuff
  remove: id => removeReporting(`/reporting/quick/${id}`, {}, {}, true) // id of the report
}

const rules = {
  fetchRuleTypes: () => get(`/rules/key_types`, {}, true),
  fetchRules: () => get(`/rules`, {}, true),
  createRule: data => post(`/rule`, data, {}, true),
  updateRule: (ruleId, data) => put(`/rule/${ruleId}`, data, {}, true),
  deleteRule: ruleId => remove(`/rule/${ruleId}`, {}, {}, true),
  fetchCaseRules: caseId => get(`/rules/case/${caseId}`, {}, true)
}

const settings = {
  fetch: () => get(`/settings`, {}, true),
  update: data => put(`/settings`, data, {}, true),
  fetchLocationSettings: id => get(`/settings/location/${id}`, {}, true),
  updateLocationSettings: (id, params) => put(`/settings/location/${id}`, params, {}, true)
}

const stats = {
  fetch: (bondsmanId, options = {}) => getStats(`/stats/${bondsmanId}${options ? buildQueryStringFromJSON(options) : ''}&site=${process.env.REACT_APP_BUILD}`, {}, false)
}

const status = {
  add: (caseId, params) => post(`/case/${caseId}/status`, params, {}, true),
  update: (caseId, statusId, params) => post(`/case/${caseId}/status/${statusId}`, params, {}, true),
  delete: (caseId, statusId) => remove(`/case/${caseId}/status/${statusId}`, {}, {}, true)
}

const surety = {
  all: () => get(`/sureties`, {}, true),
  saveAgencySuretySettings: data => put(`/sureties`, data, {}, true)
}

const user = {
  register: params => post(`/user/register`, params, {}, false),
  reset: params => post(`/user/reset`, params, {}, true),
  testText: params => post(`/user/text/test`, params, {}, true)
}

const warehouse = {
  buildAnswerGroupToken: token => postWarehouse(`/token/${token}`, {}, {}, false),
  fetchAnswerGroup: (token, jwt) => getWarehouse(`/token/${token}`, { headers: { 'X-Answer-Token': jwt } }),
  fetchAnswerGroupRequests: (token, jwt) => getWarehouse(`/token/${token}/requests`, { headers: { 'X-Answer-Token': jwt } }),
  fetchNextQuestion: (token, jwt) => getWarehouse(`/token/${token}/question`, { headers: { 'X-Answer-Token': jwt } }),
  answerQuestion: (token, questionId, params, jwt) => postWarehouse(`/token/${token}/question/${questionId}`, params, { 'X-Answer-Token': jwt }),
  bypassQuestions: (token, jwt) => postWarehouse(`/token/${token}/bypass`, {}, { 'X-Answer-Token': jwt }),
  fetchDocumentRequest: (token, requestId, jwt) => postWarehouse(`/token/${token}/request/${requestId}`, {}, { 'X-Answer-Token': jwt }),
  logAction: (token, requestId, type, params, jwt) => putWarehouse(`/token/${token}/request/${requestId}/${type}`, params, { 'X-Answer-Token': jwt }),
  markRequestComplete: (token, params, jwt) => putWarehouse(`/token/${token}/request/complete`, params, { 'X-Answer-Token': jwt }),
  buildCCAuthFormRequest: (token, data, jwt) => postWarehouse(`/token/${token}/ccauth`, data, { 'X-Answer-Token': jwt }),
  fetchAgencyAnswerGroup: token => getWarehouse(`/agency/token/${token}`, {}, true),
  fetchAgencyAnswerGroupRequests: token => getWarehouse(`/agency/token/${token}/requests`, {}, true),
  fetchAgencyAnswerGroupAnswers: token => getWarehouse(`/agency/token/${token}/answers`, {}, true),
  updateAgencyAnswerGroupAnswers: (token, params) => putWarehouse(`/agency/token/${token}/answers`, params, {}, true),
  updateAnswerGroup: (token, params) => putWarehouse(`/agency/token/${token}`, params, {}, true),
  fetchAgency: (withVault = false) => getWarehouse(`/agency?vault=${withVault ? true : false}`, {}, true),
  upsertAgency: () => put(`/bondsman/warehouse/agency`, {}, {}, true),
  updateAgencySettings: (id, params) => putWarehouse(`/agency/${id}/settings`, params, {}, true),
  deleteAllRows: id => removeWarehouse(`/agency/${id}/settings/security/rows`, {}, {}, true),
  syncWarehouse: id => putWarehouse(`/agency/${id}/sync`, {}, {}, true),
  syncEmployees: (id, params) => putWarehouse(`/agency/${id}/settings/employees`, params, {}, true),
  fetchSections: (area) => getWarehouse(`/agency/sections?area=${area}`, {}, true),
  fetchSectionsAndQuestions: (id, caseId) => getWarehouse(`/agency/${id}/group/${caseId}`, {}, false),
  fetchSectionQuestions: (sectionId) => getWarehouse(`/agency/section/${sectionId}/questions`, {}, true),
  resetSections: () => postWarehouse(`/agency/sections/reset`, {}, {}, true),
  createSection: (id, params) => postWarehouse(`/agency/${id}/section`, params, {}, true),
  updateSection: (id, params) => putWarehouse(`/agency/section/${id}`, params, {}, true),
  removeSection: (id) => removeWarehouse(`/agency/section/${id}`, {}, {}, true),
  updateSectionsOrder: (params) => putWarehouse(`/agency/sections/order`, params, {}, true),
  fetchQuestions: id => getWarehouse(`/agency/${id}/questions`, {}, true),
  createQuestionAndAssign: (agencyId, sectionId, params) => postWarehouse(`/agency/${agencyId}/section/${sectionId}/question`, params, {}, true),
  updateQuestion: (agencyId, sectionId, questionId, data) => putWarehouse(`/agency/${agencyId}/section/${sectionId}/question/${questionId}`, data, {}, true),
  removeQuestion: (agencyId, sectionId, questionId) => removeWarehouse(`/agency/${agencyId}/section/${sectionId}/question/${questionId}/remove`, {}, {}, true),
  deleteQuestion: (agencyId, sectionId, questionId) => removeWarehouse(`/agency/${agencyId}/section/${sectionId}/question/${questionId}/delete`, {}, {}, true),
  updateQuestionsOrder: (agencyId, sectionId, params) => putWarehouse(`/agency/${agencyId}/section/${sectionId}/questions/order`, params, {}, true),
  createQuestionGroup: (id, params) => postWarehouse(`/agency/${id}/question/group`, params, {}, true),
  fetchToken: () => getWarehouse(`/vault/token`, {}, true),
  fetchCaseTokens: (caseId, params = {}) => putWarehouse(`/vault/case/${caseId}`, params, {}, true),
  updateRecord: (caseId, params) => postWarehouse(`/vault/case/${caseId}`, params, {}, true),
  setupVault: agencyId => postWarehouse(`/vault/agency/${agencyId}`, {}, {}, true),
  translation: params => postWarehouse(`/translation`, params, {}, true),
  recoverVersion: (agencyId, token, versionId) => postWarehouse(`/agency/${agencyId}/token/${token}/recover`, { versionId }, {}, true),
  buildPrintable: (token, params) => postWarehouse(`/agency/token/${token}/pdf`, params, {}, true)
}

const bill = {
  getBills: params => primaryGraphql.bills.getBills(params),
  getBillByToken: token => primaryGraphql.bills.getBillByToken({ token }),
  getBillsByCaseId: id => primaryGraphql.bills.getBills({ first: 50, caseId: id }),
  sendBill: id => primaryGraphql.bills.sendBill({ id }),
  createBill: params => primaryGraphql.bills.createBill({ input: params }),
  updateBill: (id, params) => primaryGraphql.bills.updateBill({ id, input: params }),
  deleteBill: id => primaryGraphql.bills.deleteBill({ id }),
  payBill: params => primaryGraphql.bills.payBill(params)
}

const vars = {
  appearances,
  arrestee,
  auth,
  alerts,
  bondsman,
  bill,
  cases,
  checkins,
  clock,
  counties,
  courthouses,
  defendant,
  documents,
  employees,
  explorer,
  files,
  finance,
  forfeitures,
  importer,
  inventory,
  invoice,
  link,
  logs,
  lookup,
  offices,
  onboarding,
  payment_plans,
  powers,
  questionnaire,
  referrals,
  reporting,
  reports,
  rules,
  settings,
  stats,
  status,
  surety,
  user,
  warehouse
}

export default vars
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Loading from 'components/Loading'
import LoadingInner from 'components/Loading'
import ButtonPrimary from 'components/ButtonPrimary'
import TokenizedPaymentForm from 'components/TokenizedPaymentForm'

// utils
import dayjs from 'dayjs'
import api from 'lib/api'
import numeral from 'numeral'
import { loadCollectJsScript, discectError } from 'lib/utils'
import { calculateSurchargeAmount, formatMoney } from 'lib/settings-utils'

// styles
import styles from './styles.module.scss'
import BodyClassName from 'react-body-classname'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBan, faCheckCircle, faCreditCard, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { faCcAmex, faCcDiscover, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons'

const InvoicePortal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isInnerLoading, setIsInnerLoading] = useState(false)
  const [innerError, setInnerError] = useState(false)
  const [error, setError] = useState(null)
  const [makePayment, setMakePayment] = useState('CARD_INFO')
  const [paymentOption, setPaymentOption] = useState(null)
  const [paymentInfo, setPaymentInfo] = useState(null)
  const [showPaymentConfirm, setShowPaymentConfirm] = useState(false)
  const [invoice, setInvoice] = useState()

  const { token } = useParams()
  const { paymentToken, apr, aprCalculated, isSurcharge } = invoice?.finances || {}

  useEffect(()=> {
    if(!invoice) _fetchBills()
   }, []) // eslint-disable-line
  
  const _fetchBills = async () => {
    setIsLoading(true)
    try {
      const res = await api.bill.getBillByToken(token)
      setInvoice(res?.getBillByToken)
    } catch(e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsInnerLoading(true)
    if (paymentToken) {
      try {
        if (paymentToken) {
          loadCollectJsScript(paymentToken, () => {
            setIsInnerLoading(false)
          })
        } else {
          throw new Error('Public key is invalid.')
        }
      } catch(e) {
        setInnerError(discectError(e))
      }
    }
  }, [paymentToken])

  useEffect(() => {
    if (invoice && !invoice.isPaid) {
      const totalDue = invoice.invoiceTotalCents | 0
      setPaymentOption({ option: 'REMAINING', amount: totalDue, paymentId: null })
    }
  }, [invoice])

  const _getHeader = () => {
    let paymentSurchargeAmountCents = 0

    if (isSurcharge && paymentOption?.amount > 0) {
      let enteredPaymentAmountCents = paymentOption.amount
      paymentSurchargeAmountCents = calculateSurchargeAmount(enteredPaymentAmountCents, apr, aprCalculated)
    }

    return (
      <header>
        <div className={styles.paymentPage__detail__row}>
          <div className={styles.paymentPage__detail__pod}>
            <div className={styles.invoiceInfo}>
              <p>Invoice From:</p>
              <h3>{invoice?.agencyName}</h3>
            </div>
            <div className={styles.lineItems}>
              <h3>Payment Info</h3>
              {invoice?.lineItems?.map((itm, key) => {
                return(
                  <div className={styles.lineItems_item} key={key}>
                    <h4>{itm.name}</h4>
                    <p>Fee: ${formatMoney(itm.valueCents)}</p>
                  </div>
                )
              })}
              {paymentSurchargeAmountCents && (
                <div className={styles.lineItems_item}>
                  <label>Credit Card Surcharge</label>
                  <p>${numeral(formatMoney(paymentSurchargeAmountCents)).format('0,0.00')}</p>
                </div>
              )}
              <div className={styles.lineItems_item}>
                <label>Total Due</label>
                <p><strong>${numeral(formatMoney(invoice?.invoiceTotalCents + paymentSurchargeAmountCents)).format('0,0.00')}</strong></p>
              </div>
            </div>
          </div>
        </div>
        {_getSurchargeNote()}
      </header>
    )
  }

  // Choose Payment Option

  const _getSurchargeNote = () => {
    if (isSurcharge && paymentOption?.amount > 0) {
      let enteredPaymentAmountCents = paymentOption.amount
      const paymentSurchargeAmountCents = calculateSurchargeAmount(enteredPaymentAmountCents, apr, aprCalculated)
      return <p className={styles.surchargeNotice}><strong>Please Note:</strong> Use of a credit card incurs a <strong>${formatMoney(paymentSurchargeAmountCents)}</strong> surcharge being added to the payment.</p>
    }
    return null
  }

  // Card Info

  const _onSavePaymentData = data => {
    setPaymentInfo(data)
    setMakePayment('CONFIRM')
  }

  const _getCardInfo = () => {
    if (invoice?.invoiceTotalCents > 0) {
      return (
        <div className={styles.paymentPage}>
          {_getHeader()}
          <div className={styles.makePayment}>
            <div>
              <TokenizedPaymentForm
                info={invoice}
                amountDue={invoice?.invoiceTotalCents / 100}
                // isTestCase={caseInfo?.isTestCase}
                setIsLoading={setIsLoading}
                onContinue={_onSavePaymentData}
                onBack={false}
                isSaveCardHidden={true}
              />
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  // Confirm

  const _onSubmitPayment = async () => {
    setShowPaymentConfirm(false)
    setError(null)
    setIsLoading(true)
    try {
      if (paymentOption.option === 'REMAINING' || paymentOption.paymentId) {
        let invoiceCents = paymentOption.amount
        let surchargeCents = isSurcharge ? calculateSurchargeAmount(invoiceCents, apr, aprCalculated) : 0
        let totalCents = (invoiceCents + surchargeCents)
        
        // Mutation variables
        const variables = {
          id: invoice.id,
          input: {
            paymentToken: paymentInfo.token,
            paymentBreakdown: {
              totalCents,
              surchargeCents,
              invoiceCents
            },
            creditCardInfo: paymentInfo.card,
            payeeInfo: {
              ...paymentInfo.info
            }
          }
        }
        await api.bill.payBill(variables)
        setMakePayment('COMPLETE')
      } else {
        throw new Error('Payment ID Error. Please make sure you choose a Payment to pay.')
      }
    } catch(e) {
      setError(discectError(e))
    }
    setIsLoading(false)
  }

  const _getConfirm = () => {
    if (paymentInfo) {

      const _getCardInfoIcon = (card) => {
        if (['visa'].indexOf(card.type) >= 0) {
          return <FontAwesomeIcon icon={faCcVisa} /> 
        } else if (['mastercard'].indexOf(card.type) >= 0) {
          return <FontAwesomeIcon icon={faCcMastercard} /> 
        } else if (['disc','discover'].indexOf(card.type) >= 0) {
          return <FontAwesomeIcon icon={faCcDiscover} /> 
        } else if (['amex','american express'].indexOf(card.type) >= 0) {
          return <FontAwesomeIcon icon={faCcAmex} /> 
        }
        return <FontAwesomeIcon icon={faCreditCard} /> 
      }

      const _getBillingInfo = () => {
        const { info } = paymentInfo

        return (
          <div className={styles.billingInfo}>
            {info.nameFirst} {info.nameLast}<br />
            {info.address}<br />
            {info.city}, {info.state}, {info.zip}<br />
            {info.phone}<br />
            {info.email}
          </div>
        )
      }

      return (
        <div className={styles.paymentPage}>
          {_getHeader()}
          
          <div className={styles.confirmPayment}>
            <section className={styles.billingInfo__container}>
              <h5 className="rad">Billing Information</h5>
              {_getBillingInfo()}
            </section>
            <section className={styles.paymentInfo__container}>
              <h5 className="rad">Card Information</h5>
              <div>
                <p className={styles.paymentInfo}>
                  <span>{_getCardInfoIcon(paymentInfo.card)} {paymentInfo.card.number}</span>
                  <span>Exp: {paymentInfo.card.exp.substr(0,2)} / {paymentInfo.card.exp.substr(-2,2)}</span>
                </p>
              </div>
            </section>
            <div className={styles.dialog}>
              <p>This payment will be applied to your current payment plan and will satisfy oldest payments first. If the payment is more than the amount due, we will apply it to subsequent payments in order.</p>
              <p>By clicking <strong>"Make Payment"</strong> below we will be charging the card above.</p>
            </div>
          </div>
          <div className={styles.paymentPage__actions}>
            <ButtonPrimary color="grey" icon={faBan} onClick={() => setMakePayment('CARD_INFO')}>Back</ButtonPrimary>
            {!isSurcharge && <ButtonPrimary color="navy" icon={faCheckCircle} onClick={_onSubmitPayment}>Make Payment</ButtonPrimary>}
            {isSurcharge && <ButtonPrimary color="navy" icon={faCheckCircle} onClick={() => setShowPaymentConfirm(true)}>Make Payment</ButtonPrimary>}
          </div>
        </div>
      )
    }
    return null
  }

  const _getComplete = () => {
    return (
      <div className={styles.paymentPage}>
        <div className={styles.confirmPayment}>
          <div>
            <FontAwesomeIcon icon={faCheckCircle}/>
            <h3>${formatMoney(invoice.invoiceTotalCents + calculateSurchargeAmount(invoice.invoiceTotalCents, apr, aprCalculated))}</h3>
            <p>Your payment has been received. <br />We recomend saving or printing this page for your record.</p>
            <p>Thank you.</p>
          </div>
        </div>
      </div>
    )
  }

  const _getConfirmText = () => {       
    let totalCents = paymentOption.amount
    let surchargeAmountCents = calculateSurchargeAmount(totalCents, apr, aprCalculated)

    return <p>By clicking <strong>"Pay Now"</strong>, you acknowledge the total will be the entered amount of ${formatMoney(totalCents)}, plus the ${formatMoney(surchargeAmountCents)} credit card processing fee.</p>
  }

  const _getPaymentConfirmation = () => {
    let paymentCents = paymentOption.amount
    let surchargeCents = calculateSurchargeAmount(paymentCents, apr, aprCalculated)
    let totalCents = (paymentCents + surchargeCents)
    
    return (
      <div className={styles.paymentConfirm__mask}>
        <div className={styles.paymentConfirm__container}>
          <div className={styles.paymentConfirm}>
            <h3>Achnowledge Payment</h3>
            <div className={styles.paymentConfirm__total}>
              ${formatMoney(totalCents)}
            </div>
            {_getConfirmText()}
            <div className={styles.paymentConfirm__actions}>
              <ButtonPrimary color="grey" icon={faBan} onClick={() => setShowPaymentConfirm(false)}>Cancel</ButtonPrimary>
              <ButtonPrimary color="navy" icon={faCheckCircle} onClick={_onSubmitPayment}>Pay Now</ButtonPrimary>
            </div>
            <p className={styles.instructions}>When you click "Pay Now" your card will be processed.</p>
          </div>
        </div>
      </div>
    )
  }

  if(invoice?.isPaid) {
    return (
      <BodyClassName className={styles.paymentPage__template}>
      <div className={styles.paymentPage__container}>
        {invoice && (
          <div className={styles.paymentPage__wrapper}>
            <div className={styles.paymentPage__profile}>
              <div className={styles.paymentPage__profile__left}>
                <div>
                  <FontAwesomeIcon icon={faUserCircle} />
                </div>
                <div>
                  <h2>{invoice.name}</h2>
                  <p>Date: {dayjs(invoice?.invoiceDate).format('MM/DD/YYYY HH:mm ')}</p>
                </div>
              </div>
            </div>
            <div className={styles.paymentPage}>
              <div className={styles.confirmPayment}>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle}/>
                  <h3>${formatMoney(invoice.invoiceTotalCents + calculateSurchargeAmount(invoice.invoiceTotalCents, apr, aprCalculated))}</h3>
                  <p>This invoice has been paid. <br />We recomend saving or printing this page for your record.</p>
                  <p>Thank you.</p>
                </div>
              </div>
            </div>
            <div className={styles.paymentPage__poweredBy}>
              <p>Powered by</p>
              <img src="/images/SimplyBail_logo_long.png" alt="Simply Bail" />
            </div>
          </div>
        )}
        {showPaymentConfirm && _getPaymentConfirmation()}
        {isLoading && <Loading />}
      </div>
      </BodyClassName>
    )
  }

  return (
    <BodyClassName className={styles.paymentPage__template}>
      <div className={styles.paymentPage__container}>
        {invoice && (
          <div className={styles.paymentPage__wrapper}>
            <div className={styles.paymentPage__profile}>
              <div className={styles.paymentPage__profile__left}>
                <div>
                  <FontAwesomeIcon icon={faUserCircle} />
                </div>
                <div>
                  <h2>{invoice.name}</h2>
                  <p>Date: {dayjs(invoice?.invoiceDate).format('MM/DD/YYYY HH:mm ')}</p>
                </div>
              </div>
            </div>
            {error && <p className="error">{error}</p>}
            {innerError && (
              <p className={styles.publicKeyError}>{innerError}</p>
            )}
            {!innerError && (
              <>
                {!isInnerLoading && (
                  <>
                    {(makePayment === 'CARD_INFO') && _getCardInfo()}
                    {(makePayment === 'CONFIRM') && _getConfirm()}
                    {(makePayment === 'COMPLETE') && _getComplete()}
                  </>
                )}
                {isInnerLoading && <LoadingInner />}
              </>
            )}
            <div className={styles.paymentPage__poweredBy}>
              <p>Powered by</p>
              <img src="/images/SimplyBail_logo_long.png" alt="Simply Bail" />
            </div>
          </div>
        )}
        {showPaymentConfirm && _getPaymentConfirmation()}
        {isLoading && <Loading />}
      </div>
    </BodyClassName>
  )

}

export default InvoicePortal
import React from "react"
import styles from "./HeaderWithLogo.module.scss"

const HeaderWithLogo = ({ children }) => {
  return (
    <header className={styles.header}>
      <div className={styles.leftLogo}>
        <h1>
          <a href="/">
            Simply Bail - The most secure, fastest way to get someone out of
            jail
          </a>
        </h1>
      </div>
      {children}
    </header>
  )
}

export default HeaderWithLogo

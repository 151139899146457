import React from "react"
import styles from "./HeaderButton.module.scss"

const HeaderButton = ({ onClick, children, className }) => {
  return (
    <button className={`${styles.butt} ${className}`} onClick={onClick}>
      {children}
    </button>
  )
}

export default HeaderButton

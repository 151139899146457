import React from "react"
import styles from "./HeaderLink.module.scss"

const HeaderLink = ({ href, text, className }) => {
  return (
    <a className={`${styles.link} ${className}`} href={href}>
      {text}
    </a>
  )
}

export default HeaderLink

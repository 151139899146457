import { BILL_FRAGMENT } from './fragments'

const { gql } = require('graphql-request')

// Define the mutation
export const GET_BILLS = gql`
  query GetBills( $caseId: String, $first: Int, $after: String, $status: String) {
    getBills( caseId: $caseId, first: $first, after: $after, status: $status) {
      edges {
        node {
          ...billInfo
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${BILL_FRAGMENT}
`

export const GET_BILL = gql`
  query GetBill($id: Id!) {
    getBill(id: $id) {
      ...billInfo
    }
  }
  ${BILL_FRAGMENT}
`

export const GET_BILL_BY_TOKEN = gql`
  query GetBillByToken($token: String!) {
    getBillByToken(token: $token) {
      agencyName
      id
      invoiceDate
      invoiceId
      invoiceTotalCents
      isPaid
      payments {
        amount
        breakdown {
          surchargeAmount
          total
          totalAmount
        }
      }
      status
      token
      finances {
        apr
        aprCalculated
        isSurcharge
        paymentToken
      }
    }
  }
`

const queries =  {
  GET_BILLS,
  GET_BILL,
  GET_BILL_BY_TOKEN
}

export default queries